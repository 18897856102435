.bidWrapper {
  margin: 1rem 0;
}

.bidBtn {
  margin-left: 1rem !important;
  border-radius: 00.25rem !important;
  width: 40%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
}

.bidBtn:hover,
.bidBtn:active,
.bidBtn:focus,
.bidBtn:disabled {
  background-color: rgb(255, 255, 255) !important;
  outline: none !important;
  box-shadow: none !important;
}
.minBidCopy {
  padding-top: 0;
  font-size: small;
  margin-left: 0.25rem;
  margin-bottom: 0.3rem;
}
.bidInput {
  width: 60%;
  height: 3rem;
  color: black;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  background-color: white;
  font-weight: bold;
  outline: none !important;
  box-shadow: none !important;
}
.bidInput:focus {
  border: none !important;
  border: 1px solid #aaa !important;
}
.customPlaceholder {
  position: absolute;
  top: 25%;
  left: 46%;
  font-weight: bold;
  color: #aaa;
  z-index: 3;
}
.bidBtnAuctionEnded {
  width: 100%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
}

.bidBtnAuctionEnded:hover,
.bidBtnAuctionEnded:active,
.bidBtnAuctionEnded:focus,
.bidBtnAuctionEnded:disabled {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0);
  outline: none !important;
  box-shadow: none;
}

.bidInputAuctionEnded {
  display: none;
}

.bidInput:focus {
  outline: none;
}

/* REMOVE UP/DOWN ARROWS FROM INPUT */
/* Chrome, Safari, Edge, Opera */
.bidInput::-webkit-outer-spin-button,
.bidInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.bidInput[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 992px) {
  .bidInput {
    border-color: #7c2c2c;
  }
  .customPlaceholder {
    left: 44%;
  }
}
